import DealerOrderTypeCard from "@components/Dealer/DealerOrderTypeCard"
import { DealerOrderCardWrapper, DealerPromotionContainer, ListingTypeCardContainer, NavListItemBottomWrap, UserManageContentArea } from "@components/Dealer/DealerOrderTypeCard.style"
import { NavListItem } from "@components/Dealer/DealerSignIn.style"
import { UserAccountWrapperContainer } from "@components/GenericBannerContainer"
import { Layout } from "@components/Layout"
import { Checkout } from "@models/Checkout/Checkout"
import { NavListItemContent, NavListItemHeader, UserManageHeader } from "@privateComponents/private.styles"
import { getCurrentCheckout } from "@redux/localStorage/checkout"
import { H2 } from "@styles/Global.styles"
import theme from "@styles/theme"
import { graphql, Link, navigate, StaticQuery } from "gatsby"
import React from "react"
import { Divider, Dropdown, DropdownHeader, DropdownItem, DropdownMenu, Grid, List } from "semantic-ui-react"
import DealerLoginPage from "./dealer-login"
import DealerList from "@components/Dealer/DealerList"
import DealerNav from "@components/Dealer/DealerNav"
import { DealerService } from "@services/DealerService"

export type CombinationDetailsType = {
  createCombinationDetails: {
    heading: string
    description: string
    link: string
  }
  restyleCombinationDetails: {
    heading: string
    description: string
    link: string
  }
}

const DealerNewOrder = (props: { formData:CombinationDetailsType, apiUrl: string, checkout: Checkout, promotionData: any }) => {
  const { formData, checkout, apiUrl, promotionData } = props
  const dealerName = checkout.dealerDetails?.dealerName
  const salesPersonFirstName = checkout.dealerDetails?.salesPersonName.split(' ')[0]
  const salesPersonEmail = checkout.dealerDetails?.salesPersonEmail.split(' ')[0] || ""
  const salesPersonId = `${checkout.dealerDetails?.salesPersonId}` || ""

  DealerService.getDealerOrder(apiUrl, salesPersonId)
  .then(response => {
    if(response.Status != 200){
      navigate("/dealer-login")
    }
  })
  .catch(exception => {
    navigate("/dealer-login")
  })

  const dealerOptions = [
    {
      key: dealerName,
      text: dealerName,
      value: dealerName,
      selected: true
    }
  ]

  var dealerBanner = promotionData.banner.value
  return (
    <Layout version="simple">
      <UserAccountWrapperContainer bgLeftColor={theme.brand.colors.blue}
      mobilePaddingBottom={0}
      mobilePaddingTop={0}>
          <Grid>
              <Grid.Row>
                  <Grid.Column only="computer"
                  computer={4}
                  style={{
                      float: "right",
                      backgroundColor: theme.brand.colors.lightToGrey,
                      minHeight: "calc(100vh - 180px)",
                      paddingTop: "70px",
                    }}
                  >
                  <List selection verticalAlign="middle" size="huge">
                      <DealerList dealerOptions={dealerOptions}  apiUrl={apiUrl} salesPersonEmail={salesPersonEmail}/>
                      <Divider/>
                      <DealerNav activeCss={['item-active', '', '']}/>
                  </List>
                  </Grid.Column>
                  <UserManageContentArea mobile={16} tablet={16} computer={12}>
                      <UserManageHeader>
                      <Grid stackable>
                          <Grid.Column computer={10} tablet={10} mobile={16}>
                          <H2>Welcome {salesPersonFirstName}</H2>
                          </Grid.Column>
                          <Grid.Column
                          computer={6}
                          tablet={6}
                          mobile={16}
                          textAlign="right"
                          >
                          </Grid.Column>
                      </Grid>
                      </UserManageHeader>
                      {dealerBanner.length > 0 &&
                        <div style={{padding: '10px 10px 30px 63px'}}>
                          <DealerPromotionContainer>
                            <div dangerouslySetInnerHTML={{ __html: dealerBanner }} />
                          </DealerPromotionContainer>
                        </div>
                      }
                      <DealerOrderCardWrapper>
                        <Grid stretched>
                          <Grid.Column mobile={16} tablet={8} computer={8}>
                            <DealerOrderTypeCard
                              {...formData.createCombinationDetails}
                            />
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={8} computer={8}>
                            <DealerOrderTypeCard
                              {...formData.restyleCombinationDetails}
                            />
                          </Grid.Column>
                        </Grid>
                      </DealerOrderCardWrapper>
                  </UserManageContentArea>
              </Grid.Row>
          </Grid>
      </UserAccountWrapperContainer>
    </Layout>
  )
}

const DealerNewOrderPage = (props: any) => {
  const checkout= getCurrentCheckout()
  return (
    checkout.isDealerOrder ?
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              apiUrl
            }
          }
          kontentItemDealerNewOrder {
            elements {
              new_description {
                value
              }
              restyle_heading {
                value
              }
              restyle_description {
                value
              }
              new_heading {
                value
              }
            }
          }
          allKontentItemRichHtmlBlock(filter: {system: {name: {eq: "Dealer Promotion Page"}}}) {
            nodes {
              elements {
                title {
                  value
                }
                content {
                  value
                }
                banner {
                  value
                }
              }
              system {
                codename
                name
              }
            }
          }
        }
      `}
      render={data => {
        const { elements } = data.kontentItemDealerNewOrder
        const formData: CombinationDetailsType = {
          createCombinationDetails: {
            heading: elements.new_heading.value,
            description: elements.new_description.value,
            link: "/create"
          },
          restyleCombinationDetails: {
            heading: elements.restyle_heading.value,
            description: elements.restyle_description.value,
            link: "/restyle"
          },
        }
        const checkout = getCurrentCheckout()
        const apiUrl = data.site.siteMetadata.apiUrl
        const promotionData = data.allKontentItemRichHtmlBlock.nodes[0].elements
        return <DealerNewOrder checkout={checkout} formData={formData} apiUrl={apiUrl} promotionData={promotionData}/>
      }}
    ></StaticQuery>
    :
    <DealerLoginPage />
  )
}

export default DealerNewOrderPage