import { Paragraph2 } from "@styles/Global.styles"
import theme from "@styles/theme"
import React from "react"
import { CheckoutSelectionCardButton, ListingTypeCardContainer, ListingTypeCardHeading } from "./DealerOrderTypeCard.style"

type DealerOrderTypeCardProps ={
    heading: string,
    description: string,
    link:string
}

const DealerOrderTypeCard = (props : DealerOrderTypeCardProps) => {
    const {heading, description, link} = props;

    return (
    
        <ListingTypeCardContainer>
            <div>
                <ListingTypeCardHeading>{heading}</ListingTypeCardHeading>                
                <Paragraph2 color='#707070'>
                    <div dangerouslySetInnerHTML={{ __html: description }}></div>
                </Paragraph2>
            </div>                
            <div>                   
                {
                    <CheckoutSelectionCardButton id="listing-csc-select" buttonType='primary' color={theme.brand.colors.blue} link={link}>GET STARTED</CheckoutSelectionCardButton>
                }
                </div>                
        </ListingTypeCardContainer>        
    )
}

export default DealerOrderTypeCard